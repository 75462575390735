import React, {useState} from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../../containers/layout'
import GraphQLErrorList from '../../components/graphql-error-list'
import Carousel from '../../components/carousel'
import PortableText from '../../components/portableText'
import {Formik} from 'formik';
import * as Yup from 'yup';
import {fetchDatafromAPI} from '../../lib/apiHelper'
import ACTIONS from '../../services/actions'
import EMAILTEMPLATES from '../../services/emailTemplates'


export const query = graphql`
    query resetPasswordPageQuery {
        pageInfo: sanityResetPasswordPage(_id: {regex: "/(drafts.|)resetPasswordPage/"}) {
            _id
            title
            banner {
                asset {
                  url
                }
            }
            _rawBody(resolveReferences: {maxDepth: 5})
        }
    }
`

const ReserPasswordPage = props => {
    const {data, errors} = props

    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const pageInfo = (data || {}).pageInfo
    if (!pageInfo) {
        throw new Error(
        'Missing page info.'
        )
    }

    let  banner = pageInfo.banner && pageInfo.banner.asset ? pageInfo.banner.asset.url : ''

    const [completeMessage, setCompleteMessage] = useState()

    return(
        <Layout>
            {/* banner */}

            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={pageInfo.banner ? pageInfo.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className='mb-0' style={{color: 'rgb(245, 235, 220)'}}>{pageInfo.title.toUpperCase() || 'RESET PASSWORD'}</h1>
                    </div>
                </div> */}
            </div>
            </div>

            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Reset Password</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>


            {/* content */}
            <div className="container">
                <div className="row">

                    {
                        !completeMessage &&
                        <>
                        <div className="col-12 col-md-8" style={{margin: '0 auto'}}>
                            {pageInfo._rawBody && <PortableText blocks={pageInfo._rawBody} />}
                        </div>
                        <div className="col-md-6 mb-8" style={{margin: '0 auto'}}>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={ValidateSchema}
                                onSubmit={async (values, actions) => {
                                    setCompleteMessage(null)

                                    // add email template
                                    values.emailTemplate = EMAILTEMPLATES.RESET_PASSWORD_TEMPLATE
                                    // values.resetUrl = window.location.href.split('?')[0]
                                    // values.resetUrl = window.location.protocol + '//' + window.location.host + '/auth/change-password'
                                    values.resetUrl = window.location.protocol + '//' + window.location.host

                                    // if pass checking call lambda api
                                    try {
                                        // let apiEndpoint = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
                                        let apiEndpoint = process.env.NATIVE_USER_API_ENDPOINT
                                        let data = {
                                            action: ACTIONS.RESET_PASSWORD_REQUEST,
                                            values: values
                                        }

                                        let response = await fetchDatafromAPI(apiEndpoint, data)
                                        // console.log('---> response: ', response)

                                        if(response.body && response.body.bSuccess && response.body.bSuccess === true){
                                            // setCompleteMessage(response.body.message)
                                            setCompleteMessage('Check your e-mail for the confirmation link.')

                                            // reset form after success
                                            actions.resetForm()
                                        }
                                        else{
                                            actions.setErrors({failMessage: response.body.message})
                                        }

                                    } catch (error) {
                                        // console.log("log: create user -> error", error)
                                        actions.setErrors({failMessage: "Form submitting fail, Please try again or contact administrator."})
                                    }

                                }}
                            >
                                {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault() // prevent refresh page
                                            handleSubmit()
                                        }}                                    
                                    >
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input name="email" type="text" className="form-control" id="email" placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                            {errors.email && touched.email ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.email}</div> : null}
                                        </div>

                                        {
                                            isSubmitting ? 
                                            <button type="submit" className="btn btn-secondary col-12" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...
                                            </button>
                                            : <button type="submit" className="btn btn-primary col-12">Send Request</button>
                                        }

                                        {errors.failMessage && 
                                            <div className="alert alert-danger" role="alert">
                                                <h4 className="alert-heading mb-1">Fail!</h4>
                                                <hr className="mt-1" />
                                                <p>{errors.failMessage}</p>
                                            </div>
                                        }
                                    </form>                                
                                )}
                            </Formik>
                        </div>
                        </>
                    }
                    
                    {
                        completeMessage &&
                        <div className="col-12 col-md-8 mb-6" style={{margin: '0 auto'}}>
                            <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading mb-1">Successfully!</h4>
                                <hr className="mt-1" />
                                <p>{completeMessage}</p>
                            </div>
                        </div>
                    }

                </div>                
            </div>            

        </Layout>
    )
}

const initialValues = {
    email: ''
}

const ValidateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format.').required('Email is required.')
});

export default ReserPasswordPage